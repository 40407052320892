import React, { useState } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Container, Row, Col } from "react-bootstrap";
import "./RelevanceCard.css";

// Register required Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const RelevanceCard = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const data1 = {
    labels: ["2018", "2019", "2020", "2021", "2022", "2023", "2024"],
    datasets: [
      {
        label: "Paper 1",
        data: [40, 70, 80, 60, 50, 70, 90],
        backgroundColor: "#4CAF50",
      },
    ],
  };

  const data2 = {
    labels: ["2018", "2019", "2020", "2021", "2022", "2023", "2024"],
    datasets: [
      {
        label: "Paper II",
        data: [50, 60, 70, 50, 60, 80, 100],
        backgroundColor: "#2196F3",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Years",
        },
      },
      y: {
        title: {
          display: true,
          text: "Marks",
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <Container fluid className="relevance-card">
      <div className="card-header">
        <h3>Importance in UPSC Civil Exam</h3>
      </div>

        <Row>
          {/* Text Section */}
          <Col xs={12} md={7} className="text-section">
            <h5>Paper 1 - General Studies Paper</h5>
            <ul>
              <li >
                Indian Polity and Governance – Constitution, Political System,
                Panchayati Raj, Public Policy, Rights Issues
              </li>
              <li>
                Economic and Social Development Sustainable Development,
                Poverty, Inclusion, Demographics, Social Sector Initiatives,
                etc.
              </li>
            </ul>
            <h5>Paper II - General Studies II</h5>
            <ul>
              <li>
                Constitution of India - historical underpinnings, evolution,
                features, amendments, significant provisions and basic
                structure.
              </li>
            </ul>
          </Col>

          {/* Graph Section */}
          <Col xs={12} md={5} style={{ flex: 1 }} className="graph-section">
            <Bar data={data1} options={options} />
            <Bar data={data2} options={options} />
          </Col>
        </Row>
    </Container>
  );
};

export default RelevanceCard;
