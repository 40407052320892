import React from "react";
import "./Header.css";
import { Container, Row, Col } from "react-bootstrap";
import SearchBar from "../SearchBar/SearchBar";
import AccountDisplay from "../AccountDisplay/AccountDisplay";
import TopNavigationBar from "../TopNavigationBar/TopNavigationBar";

const Header = ({selectedTags, setSelectedTags, selectedYears, setSelectedYears, selectedTopics, setSelectedTopics }) => {
  return (
    <Container fluid className="header-container">
      {/* Mobile/Tablet Layout */}
      <Row className="align-items-center d-xl-none py-2">
        {/* Logo */}
        <Col xs={6} className="d-flex justify-content-start">
          <a href="/upsc" className="logo-link">
            <img
              src="/turtlerabbit_logo.png"
              alt="Logo"
              className="img-fluid logo"
            />
          </a>
        </Col>

        {/* Account Display */}
        <Col xs={6} className="d-flex justify-content-end">
          {/* <AccountDisplay /> */}
        </Col>
      </Row>

      {/* Mobile/Tablet Layout: Search Bar Row */}
      <Row className="align-items-center d-xl-none py-2">
        <Col xs={12} className="searchbar-wrapper">
          <SearchBar {...{selectedTags, selectedYears, setSelectedTags, setSelectedYears, selectedTopics, setSelectedTopics }}/>
        </Col>
      </Row>

      {/* Desktop Layout */}
      <Row className="align-items-center d-none d-xl-flex py-2">
        {/* Logo */}
        <Col xl={2} className="d-flex justify-content-start">
          <a href="/upsc" className="logo-link">
            <img
              src="/turtlerabbit_logo.png"
              alt="Logo"
              className="img-fluid logo"
            />
          </a>
        </Col>

        {/* Search Bar */}
        <Col xl={4} className="searchbar-wrapper">
          <SearchBar {...{selectedTags, setSelectedTags, selectedYears, setSelectedYears, selectedTopics, setSelectedTopics }} />
        </Col>

        {/* Top Navigation Bar */}
        <Col xl={5} className="d-flex justify-content-center">
          {/* <TopNavigationBar /> */}
        </Col>

        {/* Account Display */}
        <Col xl={1} className="d-flex justify-content-end">
          {/* <AccountDisplay /> */}
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
