import { useState, useEffect } from "react";
import MCQCard from "./MCQCard/MCQCard";
import axios from "axios";

const PYQCard = ({ selectedTags, setSelectedTags, questions, setQuestions }) => {
  


  useEffect(() => {
    const fetchQuestions = async () => {
      if (selectedTags.length === 0) {
        setQuestions([]);
        console.log(questions);
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/questions`, {
          params: { tags: selectedTags.join(",") },
        });
        console.log(response.data);
        setQuestions(response.data.questions);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, [selectedTags]); // Fetch questions whenever `selectedTags` changes

  return <MCQCard setSelectedTags={setSelectedTags} questions={questions} type={"PYQ"} pastelColor="#E8F5E9" />;
};

export default PYQCard;
