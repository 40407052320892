import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import axios from "axios";
import Onboarding from "./components/Onboarding";
import V1 from "./components/V1/V1";

// import TurnstileComponent from "./components/TurnstileComponent"; // Import Turnstile Component
import { BrowserRouter as Router, Route, Routes, useNavigate, useParams, useSearchParams, Link } from "react-router-dom";
//import Onboarding from "./firebase";

// function App() {
//   const [selectedTags, setSelectedTags] = useState([]);
//   const [questionsData, setQuestionsData] = useState({ count: 0, questions: [] });
//   const [selectedExam, setSelectedExam] = useState("upsc");
//   // const [turnstileToken, setTurnstileToken] = useState(null); // State for Turnstile token

//   const navigate = useNavigate();
//   const { exam } = useParams();
//   const [searchParams] = useSearchParams();

//   useEffect(() => {
//     if (exam) {
//       setSelectedExam(exam);
//       console.log(exam);
//     } 

//     const tagsParam = searchParams.get("tags");
//     if (tagsParam) {
//       const tagsArray = tagsParam.split(",");
//       setSelectedTags(tagsArray);
//     }
//   }, [exam, searchParams]);

//   useEffect(() => {
//     const tagsQuery = selectedTags.length ? `?tags=${selectedTags.join(",")}` : "";
//     navigate(`/${selectedExam}${tagsQuery}`, { replace: true });
//   }, [selectedExam, selectedTags, navigate]);

//   useEffect(() => {
//     const fetchQuestions = async () => {
//       if (selectedTags.length === 0) {
//         setQuestionsData({ count: 0, questions: [] });
//         return;
//       }
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/questions`, {
//           params: { tags: selectedTags.join(",") },
//         });
//         setQuestionsData(response.data);
//       } catch (error) {
//         console.error("Error fetching questions:", error);
//       }
//     };

//     fetchQuestions();
//   }, [selectedTags]);

//    // Function to create a string with an Oxford comma
//   const formatTagsWithOxfordComma = (tags) => {
//     if (tags.length === 0) return "";
//     if (tags.length === 1) return tags[0];
//     if (tags.length === 2) return tags.join(" and ");
//     return `${tags.slice(0, -1).join(", ")}, and ${tags[tags.length - 1]}`;
//   };

//   useEffect(() => {
//     // document.title = `${selectedExam.toUpperCase()} {} Questions - Turtle Rabbit`;
//     // Update document title
//     const formattedTags = formatTagsWithOxfordComma(selectedTags);
//     document.title = `${selectedExam.toUpperCase()} ${formattedTags} PYQs - Turtle Rabbit`;
//   }, [selectedExam, selectedTags]);

 
//   const handleLogoClick = () => {
//     setSelectedTags([]);
//   };
// }

export default function AppWrapper() {
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<V1 />} />
        <Route path="/:exam" element={<V1/>} />
        <Route path="/onboarding" element={<Onboarding />} />
        
      </Routes>
    </Router>
  );
}
