import React, { useState, useEffect, useRef } from "react";
import { ListGroup } from "react-bootstrap";
import "./SearchBar.css";
import { SearchIcon, DownArrowIcon } from "../Basic/BasicShapes";
import axios from "axios";



const SearchBar = ({selectedTags, setSelectedTags, selectedYears, setSelectedYears, 
  selectedTopics, setSelectedTopics, selectedExam}) => {
  const [showDialog, setShowDialog] = useState(false);
  // const [selectedYears, setSelectedYears] = useState([]);
  // const [selectedTopics, setSelectedTopics] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const dialogRef = useRef(null);
  const allRef = useRef(null);
  const inputRef = useRef(null);
  const showAllDialog = false;

  const years = Array.from({ length: 2024 - 1993 + 1 }, (_, i) => 2024 - i);
  const topics = ["Geography", "Polity", "Environment", "History"];

 useEffect(() => {
  if (showDialog) {
    const handleClickOutside = (event) => {
      console.log("Clicked element:", event.target); // Debug log

      if (
        (!dialogRef.current || !dialogRef.current.contains(event.target)) &&
        (!allRef.current || !allRef.current.contains(event.target)) 
        // (inputRef.current || !inputRef.current.contains(event.target))
      ) {
        setShowDialog(false);
        setShowSuggestions(false);
      }
    };

    // Add event listener when dialog is visible
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup: Remove event listener when dialog is hidden
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }
}, [showDialog]);

  const toggleDialog = () => {
    setShowDialog((prev) => !prev);
    setShowSuggestions(false); // Hide suggestions when toggling the dialog
  };

  const toggleYearSelection = (year) => {
    setSelectedYears((prev) =>
      prev.includes(year) ? prev.filter((y) => y !== year) : [...prev, year]
    );
  };

  const toggleTopicSelection = (topic) => {
    setSelectedTopics((prev) =>
      prev.includes(topic) ? prev.filter((t) => t !== topic) : [...prev, topic]
    );
  };

  const handleSearch = () => {
    console.log("Search Params:", {
      keyword: searchText,
      years: selectedYears,
      topics: selectedTopics,
    });
    setShowDialog(false);
    setShowSuggestions(false);
  };



  const fetchSuggestions =  async (searchText) => {
    setSearchText(searchText);
    if(searchText !== '') {
      
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggest-tags-with-freq?query=${searchText}`);
    
      if (response.data){
        setSuggestions(response.data || []);
        setShowSuggestions(true);
      }
      else {
        setSuggestions( []);
        setShowSuggestions(false);
      }

    }
    else {
      setSuggestions( []);
      setShowSuggestions(false);
    }
        
  };

  const handleSuggestionClick = (suggestion) => {
    console.log(suggestion);
    setSearchText(suggestion.Tag);
    setShowSuggestions(false);
    setSelectedTags(prev => 
      prev.find((t) => t === suggestion.Tag) ? prev : [...prev, suggestion.Tag]
    );
  };

  return (
    <div className="searchbar-container">
      <div className="all-and-search">
        {showAllDialog && <div className="all-button-container">
          <button ref={allRef} className="all-button" onClick={toggleDialog}>
            All <span className="down-arrow">
              <DownArrowIcon size={20} />
            </span>
          </button>
        </div>}
        <div className="search-container search-input-wrapper">
          <input
            ref={inputRef}
            type="text"
            className={`search-input ${
              selectedTopics.length > 0 ? "disabled-style" : ""
            }`}
            placeholder="Type a topic to search UPSC Prelims PYQs (e.g. 'Right to Education Act', 'Buddhism', 'RBI')"
            value={selectedTopics.length > 0 ? "" : searchText}
            onChange={(e) => fetchSuggestions(e.target.value)}
            readOnly={selectedTopics.length > 0}
          />
          <button className="search-button" onClick={handleSearch}>
            <SearchIcon />
          </button>
          {showSuggestions && suggestions.length > 0 && (
            <ListGroup className="suggestions-list">
              {suggestions.map((suggestion, index) => (
                <ListGroup.Item
                  key={index}
                  tabIndex={0}
                  action
                  onClick={() => {
                    console.log("Item clicked:", suggestion);
                    handleSuggestionClick(suggestion)}}
                >
                  {suggestion.Tag} ({suggestion.Freq})
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </div>
        
      </div>

      {showDialog && (
        <div className="dialog-box" ref={dialogRef}>
          <div className="dialog-column">
            <h4>Select Year</h4>
            <div className="year-list">
              {years.map((year) => (
                <div
                  key={year}
                  className={`dialog-item ${
                    selectedYears.includes(year) ? "active" : ""
                  }`}
                  onClick={() => toggleYearSelection(year)}
                >
                  {year} ({year < 2014 ? 150 : 100})
                </div>
              ))}
            </div>
          </div>

          <div className="dialog-column">
            <h4>Select Topic</h4>
            <div className="topic-list">
              {topics.map((topic) => (
                <div
                  key={topic}
                  className={`dialog-item ${
                    selectedTopics.includes(topic) ? "active" : ""
                  }`}
                  onClick={() => toggleTopicSelection(topic)}
                >
                  {topic}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
