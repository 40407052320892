import React from "react";
import "./TabCard.css";

const TabCard = ({selectedTags, setSelectedTags, questions}) => {
  const removeTag = (tag) => {
    console.log('removeTag', tag);
    setSelectedTags(selectedTags.filter((t) => t !== tag ));
  };
  return selectedTags && selectedTags.length > 0 && (
            <div className="tab-card">
            <div className="selected-topics">
                {selectedTags.map((tag, index) => (
        <span className="topic-chip-wrapper" key={index}>
            <span className="topic-chip">
            {tag}
            </span>
            <button className="remove-button" onClick={() => removeTag(tag)}>✕</button>
        </span>
        ))}
        
        
      </div>
      <div className="tab-navigation">
        {/* <span className="tab active">Importance</span> */}
        <span className="tab active">Previous year questions ({questions.length})</span>
        {/* <span className="tab">Article</span> */}
        {/* <span className="tab">Study Materials</span> */}
        {/* <span className="tab">In News</span> */}
        {/* <span className="tab">Trend</span> */}
        
      </div>
    </div>
  );
};

export default TabCard;
