import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./MCQCard.css";
import {PlusIcon, TickIcon} from '../Basic/BasicShapes'
import DownArrowIcon from "../DownArrow";

const MCQCard = ({ questions, setSelectedTags, pastelColor, type='PYQ'}) => {
  const [visibleCount, setVisibleCount] = useState(5);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedFeedback, setSelectedFeedback] = useState({});
  const [isRevealCollapsed, setIsRevealCollapsed] = useState({});
  const [warning, setWarning] = useState({});
  const abcd_list = ['a', 'b', 'c','d'];
  const ABCD_list = ['A', 'B', 'C', 'D'];
  const getNumberForOption = (option ) => {return abcd_list.indexOf(option)};

  const handleShowMore = () => {
    setVisibleCount((prev) => Math.min(prev + 5, questions.length));
  };

  const handleOptionSelect = (questionId, option) => {
    console.log(questionId, option);
    setSelectedOption((prev) => ({ ...prev, [questionId]: option }));
    setWarning((prev) => ({ ...prev, [questionId]: false }));
  };

  const toggleReveal = (questionId) => {
    console.log(questionId, selectedOption[questionId]);
    if (!selectedOption[questionId]) {
      console.log(selectedOption[questionId]);
      setWarning((prev) => ({ ...prev, [questionId]: true }));
      return;
    }
    setIsRevealCollapsed((prev) => ({
      ...prev,
      [questionId]: !prev[questionId],
    }));
  };

  const handleFeedbackSelect = (questionId, feedback) => {
    setSelectedFeedback((prev) => ({ ...prev, [questionId]: feedback }));
  };

  const handleTagSelection = (tag) => {
    setSelectedTags(prev => 
          prev.find((t) => t === tag) ? prev : [...prev, tag]
        );
  };
  return questions && questions.length >0 && (
    <Container fluid className="mcq-card"  >
      <div className="card-header">
        <h3>Found {questions.length} previous year questions</h3>
      </div>
      <div >
        {questions.slice(0, visibleCount).map((question, index) => (
          <div
            key={index}
            className={`question-card` }
          >
          <div className="question-container">
            <span className="question-number" >Q {index +1}</span>
             <h3 className="question-text">{question.question}</h3>
             </div>
          
            <ul className="options-list">
              {question.options.map((option, optIndex) => (
                <li
                  key={optIndex}
                  className={`option ${
                    selectedOption[index]
                      ? selectedOption[index] === option
                        ? option === question.options[getNumberForOption(question.correct_choice)]
                          ? "correct-answer"
                          : "incorrect-answer"
                        : option === question.options[getNumberForOption(question.correct_choice)]
                        ? "correct-answer-highlight"
                        : ""
                      : ""
                  }`}
                  onClick={() => handleOptionSelect(question.id, option)}
                >
                  <strong>{ABCD_list[optIndex]}.</strong> {option}
                </li>
              ))}
            </ul>
            {selectedOption[question.id] && (
              <Row className="feedback-container">
                {selectedOption[question.id] ===
                questions.find((q) => q.id === question.id).correctAnswer ? (
                  ["Guessed it right", "Knew this topic before", "Other"].map(
                    (feedback, i) => (
                      <Col xs="auto" key={i}>
                        <button
                          className={`outline-button ${
                            selectedFeedback[question.id] === feedback
                              ? "active-feedback"
                              : ""
                          }`}
                          onClick={() =>
                            handleFeedbackSelect(question.id, feedback)
                          }
                        >
                          {feedback}
                          <span
                            className={`icon ${
                              selectedFeedback[question.id] === feedback
                                ? "checked"
                                : ""
                            }`}
                          >
                            {selectedFeedback[question.id] === feedback && <TickIcon/>}
                          </span>
                        </button>
                      </Col>
                    )
                  )
                ) : (
                  [
                    "Studied but forgot",
                    "Haven't studied it yet",
                    "Other",
                  ].map((feedback, i) => (
                    <Col xs="auto" key={i}>
                      <button
                        className={`outline-button ${
                          selectedFeedback[question.id] === feedback
                            ? "active-feedback"
                            : ""
                        }`}
                        onClick={() =>
                          handleFeedbackSelect(question.id, feedback)
                        }
                      >
                        {feedback}
                        <span
                          className={`icon ${
                            selectedFeedback[question.id] === feedback
                              ? "checked"
                              : ""
                          }`}
                        >
                          {selectedFeedback[question.id] === feedback && <TickIcon/>}
                        </span>
                      </button>
                    </Col>
                  ))
                )}
              </Row>
            )}
            {warning[question.id] && (
              <p className="warning-text">You must select an option first.</p>
            )}
            <div
              className={`accordion-box ${
                isRevealCollapsed[question.id] ? "expanded" : ""
              }`}
              onClick={() => toggleReveal(question.id)}
            >
              <div className="accordion-header">
                {isRevealCollapsed[question.id]
                  ? "Hide Answer ^"
                  : (<>
                  Reveal Answer <DownArrowIcon size={24}/>
                    </>
                  )}
              </div>
              {isRevealCollapsed[question.id] && (
                <div className="accordion-content">
                  <p>
                    <strong>Correct Answer:</strong> {question.correctAnswer}
                  </p>
                  <p>
                    <strong>Explanation:</strong> {question.explanation}
                  </p>
                </div>
              )}
            </div>
            { (
              <div className="reference-tags">
                {question.tags.map((tag, i) => (
                  <div key={i} className="tag-item" onClick={()=> handleTagSelection(tag)}>
                    <span className="tag-text">{tag}</span>
                    <PlusIcon className="plus-icon" />
                    {/* <span className="tag-icon">+</span> */}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        {visibleCount < questions.length && (
          <div className="blur-effect">
            <button className="show-more" onClick={handleShowMore}>
              Show More
            </button>
          </div>
        )}
      </div>
    {/* </div> */}
    </Container>

      );
};

export default MCQCard;