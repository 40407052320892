import { BrowserRouter as Router, Route, Routes, useNavigate, useParams, useSearchParams, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from 'axios'

import { Container, Row, Col } from "react-bootstrap";
import Header from "../Header/Header";
import RelevanceCard from "../RelevanceCard/RelevanceCard";
import QuizCard from "../QuizCard/QuizCard";
import MCQCard from "../MCQCard/MCQCard";
import CurrentAffairsCard from "../CurrentAffairsCard/CurrentAffairsCard";
import TabCard from "../TabCard/TabCard";
import PYQCard from "../PYQCard";

import "./V1.css";


const V1 = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [questions, setQuestions] = useState([]);

  // const [questionsData, setQuestionsData] = useState({ count: 0, questions: [] });
  const [selectedExam, setSelectedExam] = useState("upsc");

  const navigate = useNavigate();
  const { exam } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (exam) {
      setSelectedExam(exam);
      // console.log('exam', exam);
    } 

    const tagsParam = searchParams.get("tags");
    if (tagsParam) {
      const tagsArray = tagsParam.split(",");
      setSelectedTags(tagsArray);
      // console.log('selectedTags', selectedTags);
    }
  }, [exam, searchParams]);


  useEffect(() => {
    // const _selectedTags = 
    const tagsQuery = selectedTags.length ? `?tags=${selectedTags.join(",")}` : "";
    if (window.location.search !== tagsQuery) {
    navigate(`/${selectedExam}${tagsQuery}`, { replace: true });
    }
  }, [selectedExam, selectedTags, navigate]);

  // Function to create a string with an Oxford comma
  const formatTagsWithOxfordComma = (tags) => {
    if (tags.length === 0) return "";
    if (tags.length === 1) return tags[0];
    if (tags.length === 2) return tags.join(" and ");
    return `${tags.slice(0, -1).join(", ")}, and ${tags[tags.length - 1]}`;
  };

  useEffect(() => {
    const formattedTags = formatTagsWithOxfordComma(selectedTags);
    document.title = `UPSC ${formattedTags} PYQs - Turtle Rabbit`;
  }, [selectedExam, selectedTags]);

  

  return (
    <div className="v1-container">
      <Header {...{selectedTags, setSelectedTags, selectedYears, setSelectedYears, selectedTopics, setSelectedTopics }}/>
      <Container fluid>
        {/* Desktop Layout */}
        <Row className="d-none d-xl-flex">
          {/* First Column */}
          <Col xl={9} className="first-column">
          <TabCard  {...{selectedTags, setSelectedTags, questions}}/>
            {/* <RelevanceCard /> */}
            <PYQCard {...{selectedTags, setSelectedTags, questions, setQuestions}}/>
            {/* <MCQCard pastelColor="#E8F5E9" tags={selectedTags} /> */}
            {/* <MoreMCQCard /> */}
          </Col>

          {/* Second Column */}
          <Col xl={3} className="second-column">
            {/* <QuizCard setSelectedYears  /> */}
            {/* <CurrentAffairsCard /> */}
            {/* <CurrentAffairCard /> */}
          </Col>
        </Row>

        {/* Mobile/Tablet Layout */}
        <Row className="d-flex d-xl-none">
          <Col xs={12} className="single-column">
          <TabCard  {...{selectedTags, setSelectedTags, questions}}/>
          <PYQCard {...{selectedTags, setSelectedTags, questions, setQuestions}}/>
            {/* <RelevanceCard /> */}
            {/* <QuizCard /> */}
            {/* <MCQCard questions={questions} pastelColor="#E8F5E9" /> */}
            {/* <CurrentAffairsCard /> */}
            {/* <PYQCard /> */}
            {/* <MoreMCQCard /> */}

          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default V1;
